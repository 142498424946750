












































































































































import Component, { mixins } from 'vue-class-component';
import { currencyFormat, loadWidget } from '@/utils/helpers';
import { namespace } from 'vuex-class';
import { IOrganizations, IPaginate, IStore, ITransactions, IUser } from '@/types/types';
import timestamp from '@/filters/timestamp';
import mediumZoom from 'medium-zoom';
import { Watch } from 'vue-property-decorator';
import WidgetMixins from '@/mixins/WidgetMixins';
import DialogMixins from '@/mixins/DialogMixins';
import moment from 'moment';
import qs from 'qs';
const transactionsModule = namespace('transactions');
const organizationModule = namespace('organizations');
const authModule = namespace('auth');
@Component({
  name: 'Transactions',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    Placeholder: loadWidget('widgets/Placeholder'),
  },
  filters: {
    currencyFormat,
    timestamp,
  },
})
export default class Transactions extends mixins(WidgetMixins, DialogMixins) {
  @transactionsModule.Getter('getAllTransactions') transactions!: ITransactions[];
  @transactionsModule.Getter('getPagination') pagination!: IPaginate;
  @organizationModule.Getter('getAllOrganizations') organizations!: IOrganizations[];
  @authModule.Getter('getUserDetails') details!: IUser & IStore;
  menu: boolean = false;
  options: any = {};
  searchTerm: string | null = null;
  date: string | null = null;
  statuses: Array<string> = ['ALL', 'SUCCESS', 'FAILED'];
  query: any = {};
  startDateMenu: boolean = false;
  endDateMenu: boolean = false;
  img: string = require('@/assets/images/clip-reading-of-books-1.png');

  startDate: string | null = null;
  endDate: string | null = null;
  status: string | null = 'ALL';
  organizationId: string | null = null;

  @Watch('organizationId')
  onOrganizationIdChange(value: string | null): void {
    const orgName = this.organizations.find(({ _id }) => _id === value)?.name;
    this.query = { ...this.query, organizationId: value, orgName };
  }

  @Watch('startDate')
  onStartDateChange(date: string): void {
    this.query = { ...this.query, startDate: date };
  }

  @Watch('endDate')
  onEndDateChange(date: string): void {
    this.query = { ...this.query, endDate: date };
  }

  downloadFile(ext: string): void {
    this.query = { ...this.query, download: true, ext };
    this.$store.dispatch('organizations/downloadAccessCountReport', this.query);
  }

  created(): void {
    this.$store.dispatch('auth/me');
    this.$store.dispatch('organizations/list', false);
  }
  mounted(): void {
    mediumZoom('.productPreview');
  }
}
